.Page {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Losses {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.Loss {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0px 20px 20px 20px;
}

.Card {
    margin: 20px;
    width: 500px;
}

.CardText {
    padding-bottom: 16px;
}

.GoodspeedGif {
    border-radius: 5px;
}

@media screen and (max-width: 550px) {
    .Card {
        width: 325px !important;
    }
}