.Page {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
